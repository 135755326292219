import { Utils } from './utils'

export default class Validation extends Utils {
	#rules;
	#param;

	constructor(rules, param) {
		super()
		this.#rules = rules
		this.#param = param
	}

	validate (data, rule) {
		for (const [key, value] of Object.entries(rule)) {
			switch (key) {
				case 'required': return value ? this.required(data) : true
				case 'min': return this.min(data, value)
				case 'max': return this.max(data, value)
				case 'count': return this.count(data, value)
			}
		}

		return true
	}

	start () {
		let result = ''
		for (const [key, value] of Object.entries(this.#rules)) {
			const item = this.#param[key]
			for (let i = 0; i < value.length; i++) {
				if (!this.validate(item, value[i])) {
					result = value[i].message
					break
				}
			}
		}
		return result
	}
}



